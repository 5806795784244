import React from 'react';
import Loader from '../../Loader/Loader';
import './styles.css';

import {
  filterFreeTicketsRates,
  getTicketPriceConsideringFreeTickets,
  filterDiscountRates,
  priceFormat,
  getFreeTickets,
  roundedPrice,
} from '../../../utils/calculateDiscountAndCredits';
import DreamHomeBanner from './dream-home-banner';
import FirstPrizeIcon from './first-prize-icon';
import SecondPrizeIcon from './second-prize-icon';

const labelsClassNames = {
  'MOST POPULAR': 'most-popular',
  'LOWEST TICKET PRICE': 'lowest-ticket-price',
};

const DoublePrizeTicketCard = ({
  type,
  ticket,
  activeTicket,
  loading,
  onClick,
  raffles,
  isExpired,
  theme,
  label,
  result,
  ticketsCount,
}) => {
  const discountsMapText = {
    15: '2 for 1',
    25: '3 for 1',
    50: '4 for 1',
  };
  const discountTicketsMapText = {
    15: '50',
    25: '150',
    50: '500',
  };

  const oldMapPrice = {
    "£10": "",
    "£25": "£50",
    "£50": "£150",
    "£100": "£400",
  }
  // START OF API
  const getTotalPrice = () => {
    const actualDiscount = filterDiscountRates(
      result.discountRates,
      ticketsCount
    );
    let price = actualDiscount
      ? ticketsCount * actualDiscount.newPrice
      : ticketsCount * result.prize.ticketPrice;
    return priceFormat(Math.ceil(price));
  };

  const getTicketsCount = () => {
    let freeTickets = 0;
    const freeTicketsRates = getFreeTickets(
      result.prize,
      result.generalSettings
    );
    const actualFreeTicketsObject = filterFreeTicketsRates(
      freeTicketsRates,
      ticketsCount
    );
    if (actualFreeTicketsObject) {
      freeTickets = actualFreeTicketsObject.extra;
    }
    return freeTickets + ticketsCount;
  };

  const pricePerTicket =
    getTicketPriceConsideringFreeTickets(
      result.prize,
      result.generalSettings,
      ticketsCount,
      result.discountRates,
      raffles.length > 1
    ) || `£${result.prize.ticketPrice} `;
  const totalCost = getTotalPrice();

  const ticketsCountString = `${getTicketsCount()} Entries`;
  const doubleTicketsCountString1 = `${getTicketsCount()} £2m Cash Draw ticket${
    getTicketsCount() > 1 ? 's' : ''
  }`;
  const doubleTicketsCountString2 = `${getTicketsCount()} £3m Cash Draw ticket${
    getTicketsCount() > 1 ? 's' : ''
  }`;

  // const handleDesktopPrizeName = () => {
  //   if (isDoubleTickets) {
  //     const firstPrize = activeRaffles[0];
  //     const secondPrize = activeRaffles[1];
  //     const firstPrizeName = `${getTicketsCount()} ${firstPrize.title.replace('Cash Draw', '')} ticket${
  //       getTicketsCount() > 1 ? 's' : ''
  //     }`;
  //     const secondPrizeName = `${getTicketsCount()} ${secondPrize.title.replace('Cash Draw', '')} ticket${
  //       getTicketsCount() > 1 ? 's' : ''
  //     }`;
  //     return (
  //       <React.Fragment>
  //         <p className={'tickets-count black'}>{firstPrizeName}</p>
  //         <p className={'tickets-count space'}>+</p>
  //         <p className={'tickets-count gray'}>{secondPrizeName}</p>
  //         <p className="per-ticket">{`${pricePerTicket} per ticket`}</p>
  //       </React.Fragment>
  //     );
  //     return activeRaffles.map((raffle, index) => {
  //       const title = `${getTicketsCount()} ${raffle.title.replace('Cash Draw', '')} ticket${
  //         getTicketsCount() > 1 ? 's' : ''
  //       }`;
  //       return (
  //         <p key={index} className={activeRaffles.length - 1 !== index ? 'tickets-count black' : 'tickets-count gray'}>
  //           {activeRaffles.length - 1 === index ? '+ ' : ''}
  //           {title}
  //         </p>
  //       );
  //     });
  //   } else {
  //     const ticketsCountValue = `${getTicketsCount()} ticket${getTicketsCount() > 1 ? 's' : ''}`;
  //     return (
  //       <React.Fragment>
  //         <p className="tickets-count">{ticketsCountValue}</p>
  //         <p className="per-ticket">{`${pricePerTicket} per ticket`}</p>
  //       </React.Fragment>
  //     );
  //   }
  // };

  // const handleMobilePrizeName = () => {
  //   if (isDoubleTickets) {
  //     const firstPrize = activeRaffles[0];
  //     const secondPrize = activeRaffles[1];
  //     const firstPrizeName = `${getTicketsCount()} ${firstPrize.title.replace('Cash Draw', '')} ticket${
  //       getTicketsCount() > 1 ? 's' : ''
  //     }`;
  //     const secondPrizeName = `+ ${getTicketsCount()} ${secondPrize.title.replace('Cash Draw', '')} ticket${
  //       getTicketsCount() > 1 ? 's' : ''
  //     }`;
  //     return (
  //       <p className="tickets-count">
  //         {firstPrizeName}
  //         <p className="tickets-count">{secondPrizeName}</p>
  //       </p>
  //     );
  //   } else {
  //     const ticketsCountValue = `${getTicketsCount()} ticket${getTicketsCount() > 1 ? 's' : ''}`;
  //     return <p className="tickets-count">{ticketsCountValue}</p>;
  //   }
  // };

  // END OF API
  const isDoublePrize = raffles.length > 1;
  const handleTitle = (bundle) => {
    // example  £2m Draw x 2,000 tickets
    if (raffles.length > 1) {
      const firstPrize = raffles[0];
      const secondPrize = raffles[1];
      const firstPrizeTitle = firstPrize.bundleTitle
        ? firstPrize.bundleTitle
        : firstPrize.title;
      const secondPrizeTitle = secondPrize.bundleTitle
        ? secondPrize.bundleTitle
        : secondPrize.title;

      const firstPrizeName = `${firstPrizeTitle} x ${getTicketsCount()} tickets`;
      const secondPrizeName = `${secondPrizeTitle} x ${getTicketsCount()} tickets`;
      return (
        <React.Fragment>
          <p className='details-rows'>
            <span className='details-row'>{firstPrizeName}</span>
          </p>
          <p className='details-rows'>
            <span className='details-row'>{secondPrizeName}</span>
          </p>
        </React.Fragment>
      );
      return raffles.map((raffle, index) => {
        return (
          <p
            key={raffle._id}
            className={index === raffles.length - 1 ? 'orange' : undefined}
          >
            {`${ticket.tickets} ${raffle.title} tickets`}
            {index !== raffles.length - 1 ? ' +' : ''}
          </p>
        );
      });
    } else if (raffles.length === 1) {
      return (
        <>
          {/* £2m Draw x 2,000 tickets */}
          <p className='details-rows'>
            <span className='details-row'>{`${getTicketsCount()} tickets`}</span>
            <span className='details-row discount-tickets'>{`${discountTicketsMapText[bundle]} tickets`}</span>
          </p>
        </>
      );
    } else {
      return <p>Raffle Title</p>;
    }
  };
  const handlePagediscountTitle = () => {
    return (
      <>
        <p>
          {`${getTicketsCount()} ${raffles[0].title.replace(
            'Cash Draw',
            ''
          )} tickets`}{' '}
        </p>
        {/* <p className='orange'>{`${ticket.tickets} ${raffles[0].title} tickets`}</p> */}
      </>
    );
  };
  // const isDiscount = ticket.totalCost !== calculateDiscountPrice(ticket);

  const getBannerDetails = (prize) => {
    try {
      if (!prize) {
        return {
          title: 'PRIZE',
          subtitle: 'DREAM HOME',
        };
      }
      let prizeTitle = prize.bundleTitle ? prize.bundleTitle : prize.title;
      let title = prizeTitle ? prizeTitle.split(' ')[0].toUpperCase() : 'PRIZE';
      let subtitle = prizeTitle ? prizeTitle.split(' ').slice(1).join(' ').toUpperCase() : 'DREAM HOME';
      let array = prizeTitle ? prizeTitle.split(' ').slice(0, 2) : [];
      if (!array.length || array.length <= 1) {
        return {
          title: 'PRIZE',
          subtitle: 'DREAM HOME',
        };
      }
      return {
        title: title,
        subtitle: subtitle,
      };
    } catch (err) {
      return {
        title: 'PRIZE',
        subtitle: 'DREAM HOME',
      };
    }
  };
  
  const labelClassName = label ? labelsClassNames[label] : '';
  const banner1Details = getBannerDetails(raffles[0]);
  const banner2Details = getBannerDetails(raffles[1]);
  return (
    <div
      className={[
        'ticket-card',
        ticket.discount === 50 ? 'premium' : 'small',
        theme === 'light' ? 'ticket-card_white' : 'ticket-card_green',
        labelClassName,
        isDoublePrize ? 'double' : '',
      ].join(' ')}
    >
      <div className='ticket-card__grid'>
        <div className='ticket-card-header'>
          <div className='header-prizes-block'>
            <div className='prize-item'>
              <FirstPrizeIcon />
              <DreamHomeBanner title={banner1Details.title} subtitle={banner1Details.subtitle} />
              <p>x {getTicketsCount()} tickets</p>
            </div>
            <div className='vertical-divider'></div>
            <div className='prize-item'>
              <SecondPrizeIcon />
              <DreamHomeBanner title={banner2Details.title} subtitle={banner2Details.subtitle} />
              <p>x {getTicketsCount()} tickets</p>
            </div>
          </div>
        </div>
        <div className='header-divider'></div>
        {/* <div className='details'>
          {isDoublePrize
            ? handleTitle(ticket)
            : type === 'pagediscount'
            ? handlePagediscountTitle(ticket)
            : handleTitle(ticket)}
        </div> */}
        <div className='ticket-card__main'>
          <div className='price'>
            {/* {  <p className='discounted'> {totalCost}</p>} */}
            {/* <p className={isDiscount ? '' : 'no-discount'}>
            £{calculateDiscountPrice(ticket)}
          </p> */}
            <p className='discounted'>{oldMapPrice[totalCost]}</p>
            <p className={'no-discount'}>{totalCost}</p>
            {/* <p className='per-ticket'>{`${pricePerTicket} per ticket`}</p> */}
          </div>
          <button
            onClick={() => {
              onClick({
                numOfTickets: ticketsCount,
                prizeType: 'raffle',
                tickets: getTicketsCount(),
              })();
            }}
            disabled={loading || isExpired}
            className='ticket-card__btn'
          >
            {loading && activeTicket === ticket ? (
              <>
                <Loader text='adding...' />
              </>
            ) : (
              <>Buy now</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DoublePrizeTicketCard;
