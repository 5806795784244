import React, { useRef } from "react";
import Header from "../Header/Header";
import SectionWrapper from "../common/SectionWrapper/SectionWrapper";
import TicketList from "../TicketList/TicketList";
import TimelineBlock from "../TimelineBlock/TimelineBlock";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import DreamDraw from "../DreamDraw/DreamDraw";
import CharityBlock from "../CharityBlock/CharityBlock";
import MobileCTAButtonBottom from "../mobile-cta-button-bottom";
import "./styles.css";

const Main = (props) => {
  const {
    prize,
    prizeId,
    raffles,
    raffle,
    isExpired,
    activeRaffles,
    subscriptionModels,
    data,
    onCTAClick,
    onLearnMoreClick,
    onLearnMoreCharityClick,
    onEnterNowClick,
    widgets,
  } = props;
  const ticketSelectorRef = useRef(null);

  const handleScroll = () => {
    if (ticketSelectorRef.current) {
      ticketSelectorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  return (
    <>
      {prizeId ? (
        <div className="app">
          <Header raffle={raffle} />
          <main>
            <Hero />
            <MobileCTAButtonBottom
              variant='variant-2'
              // onClick={onEnterNowClick}
              // onClick={handleScroll}
              currentRaffle={raffle}
              withCountdown
              raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
            />
            <SectionWrapper bg="bg-2">
              <TicketList
                reference={ticketSelectorRef}
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="light"
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <CharityBlock
                onCTAClick={onCTAClick}
                onLearnMoreClick={onLearnMoreClick}
                onLearnMoreCharityClick={onLearnMoreCharityClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-2">
              <TimelineBlock
                data={data.stepper}
                title={""}
                subtitle={""}
                widget={widgets.timelineWidget}
                onCTAClick={onCTAClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <TicketList
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="dark"
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <div className="footer-margin"></div>
            </SectionWrapper>
            <Footer insideComponent={true} widget={widgets.footerWidget} />
          </main>
        </div>
      ) : (
        <main className="no-prize">
          <h1>No prize</h1>
        </main>
      )}
    </>
  );
};

export default Main;
